<template>
  <div class="banner">
    <img src="../../../public/HongLottary/Homepage/Elements/Banner1.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>
