<template>
  <div class="content">
    <div class="content-flex">
      <aside class="aside-content">
        <div class="aside-wrapper">
          <div class="ht-card green">
            <div class="ht-card-wrapper">
              <div class="ht-card-header">
                <h1 class="">ตรวจล็อตเตอรี่</h1>
              </div>
              <form action="">
                <ul>
                  <li>
                    <select class="form-select" value="16 มิถุนายน 2564">
                      <option value="16 มิถุนายน 2564">16 มิถุนายน 2564</option>
                    </select>
                  </li>
                  <li>
                    <input class="form-control" maxlength="6" type="text" placeholder="กรอกตัวเลข เพื่อตรวจสลาก" />
                  </li>
                  <li>
                    <input class="form-control" maxlength="6" type="text" placeholder="กรอกตัวเลข เพื่อตรวจสลาก" />
                  </li>
                  <li>
                    <input class="form-control" maxlength="6" type="text" placeholder="กรอกตัวเลข เพื่อตรวจสลาก" />
                  </li>
                </ul>
                <button type="submit" class="ht-button gray">ตรวจล็อตเตอรี่</button>
              </form>
            </div>
          </div>
          <!-- end check lottery form -->
          <div class="ht-card gray">
            <div class="ht-card-wrapper">
              <div class="">
                <h1 class="">ข่าวหวยประจำวัน</h1>
              </div>
              <ul>
                <li>
                  <div class="news-img">
                    <img src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" />
                  </div>
                  <div class="news-detail">
                    <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                  </div>
                </li>
                <li>
                  <div class="news-img">
                    <img src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" />
                  </div>
                  <div class="news-detail">
                    <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                  </div>
                </li>
                <li>
                  <div class="news-img">
                    <img src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" />
                  </div>
                  <div class="news-detail">
                    <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                  </div>
                </li>
                <li>
                  <div class="news-img">
                    <img src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" />
                  </div>
                  <div class="news-detail">
                    <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                  </div>
                </li>
              </ul>

              <!-- end content check lottery -->

              <div>
                <button class="ht-button green">เพิ่มเติม</button>
              </div>
            </div>
          </div>
          <!-- end daily lottery -->
        </div>
      </aside>
      <main class="main-content">
        <div class="main-content-wrapper">
          <div class="ht-card gray">
            <div class="ht-card-wrapper">
              <div class="">
                <h1 class="">ผลสลากกินแบ่งรัฐบาล</h1>
              </div>
              <div class="">
                <form class="" action="">
                  <select class="form-select" name="" id="" value="16 มิถุนายน 2564">
                    <option value="16 มิถุนายน 2564">16 มิถุนายน 2564</option>
                  </select>
                </form>
              </div>
              <div class="lottary-result">
                <div class="lottary-result-wrapper">
                  <div class="lottary-item">
                    <div class="ht-card white">
                      <div class="ht-card-wrapper">
                        <div class="ht-card-header-item">
                          <h3>รางวัลที่ 1</h3>
                        </div>
                        <div class="number">
                          <h4 class="">691861</h4>
                        </div>
                        <span class=""
                          >รางวัลละ <br />
                          6,000,000 บาท</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- end lottery NO item -->
                  <div class="lottary-item">
                    <div class="ht-card white">
                      <div class="ht-card-wrapper">
                        <div class="ht-card-header-item">
                          <h3>รางวัลเลขหน้า 3 ตัว</h3>
                        </div>
                        <div class="number">
                          <h4 class="">691 459</h4>
                        </div>
                        <span class=""
                          >2 รางวัล รางวัลละ <br />
                          4,000 บาท</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- end lottery NO item -->
                  <div class="lottary-item">
                    <div class="ht-card white">
                      <div class="ht-card-wrapper">
                        <div class="ht-card-header-item">
                          <h3>รางวัลเลขท้าย 3 ตัว</h3>
                        </div>
                        <div class="number">
                          <h4 class="">861 254</h4>
                        </div>
                        <span class=""
                          >2 รางวัล รางวัลละ <br />
                          4,000 บาท</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- end lottery NO item -->
                  <div class="lottary-item">
                    <div class="ht-card white">
                      <div class="ht-card-wrapper">
                        <div class="ht-card-header-item">
                          <h3>รางวัลเลขท้าย 2 ตัว</h3>
                        </div>
                        <div class="number">
                          <h4 class="">69</h4>
                        </div>
                        <span class=""
                          >รางวัลละ <br />
                          2,000 บาท</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- end lottery NO item -->
                </div>
              </div>
            </div>
          </div>

          <!-- end government lottery results -->

          <div class="side-prize-result">
            <div class="ht-card gray">
              <div class="ht-card-wrapper">
                <div class="side-prize">
                  <div class="side-prize-header">
                    <h3 class="mr-5">รางวัลข้างเคียงรางวัลที่ 1</h3>
                    <span class="">รางวัลละ 100,000 บาท</span>
                  </div>
                  <ul>
                    <li>254687</li>
                    <li>123598</li>
                  </ul>
                </div>
                <!-- end 1st prize -->
                <div class="side-prize">
                  <div class="side-prize-header">
                    <h3 class="mr-5">รางวัลที่ 2</h3>
                    <span class="">รางวัลละ 200,000 บาท</span>
                  </div>
                  <ul>
                    <li>2549876</li>
                    <li>0215474</li>
                    <li>3548126</li>
                    <li>4715968</li>
                    <li>5687152</li>
                  </ul>
                </div>
                <!-- end 2rd prize -->
                <div class="">
                  <button class="ht-button green">ตรวจสลากกินแบ่งรัฐบาลทั้งหมด</button>
                </div>
              </div>
            </div>
          </div>
          <!-- end side prize -->

          <div class="helper">
            <div class="helper-wrapper">
              <div class="helper-items">
                <a href="#" class="item">
                  <div class="item-wrapper">
                    <div class="item-content">
                      <font-awesome-icon :icon="['fas', 'users']" class="icon" />
                      <p class="text">ตัวแทนจำหน่าย</p>
                    </div>
                  </div>
                </a>
                <a href="#" class="item">
                  <div class="item-wrapper">
                    <div class="item-content">
                      <font-awesome-icon :icon="['fas', 'building']" class="icon" />
                      <p class="text">เกี่ยวกับเรา</p>
                    </div>
                  </div>
                </a>
                <router-link to="/appeal" class="item">
                  <div class="item-wrapper">
                    <div class="item-content">
                      <font-awesome-icon :icon="['fas', 'phone-alt']" class="icon" />
                      <p class="text">ร้องเรียน</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
