<template>
  <div class="container">
    <PopolarNO />
  </div>
  <Banner />
  <div class="container">
    <Content />
  </div>
</template>

<script>
// @ is an alias to /src
import PopolarNO from '@/components/homepage/PopolarNO'
import Content from '@/components/homepage/Content'
import Banner from '@/components/homepage/Banner'
export default {
  name: 'Home',
  components: { PopolarNO, Content, Banner }
}
</script>
