<template>
  <div class="report-lottary">
    <div class="wrapper-header">
      <h1 class="">1 กรกฏาคม 2564</h1>
      <span class="">เลขท้าย 2 ตัวที่มีคนซื้อมากที่สุด <b>10 อันดับ</b></span>
      <ul>
        <li>
          <h4>25</h4>
        </li>
        <li>
          <h4>45</h4>
        </li>
        <li>
          <h4>13</h4>
        </li>
        <li>
          <h4>53</h4>
        </li>
        <li>
          <h4>23</h4>
        </li>
        <li>
          <h4>22</h4>
        </li>
        <li>
          <h4>86</h4>
        </li>
        <li>
          <h4>50</h4>
        </li>
        <li>
          <h4>97</h4>
        </li>
        <li>
          <h4>79</h4>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopularNO'
}
</script>
